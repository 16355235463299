import React from 'react';
import Layout from '../../components/Layout';
// import blogd1 from '../../assets/images/2022/medical-outreach/fakenews.jpg';
import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';
import { Link } from 'gatsby';

const NewsDetails = () => {
  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                {/* <img src={blogd1} alt='' /> */}
              </div>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>
                  DISCLAIMER! DISCLAIMER!! DISCLAIMER!!!
                </h2>
                <h3>FRAUD ALERT</h3>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  The attention of the Management of Lagos University Teaching
                  Hospital has been drawn to a <strong>FALSE</strong>{' '}
                  information circulating on the Social Media Platform
                  especially <strong>“FACEBOOK”</strong> informing the Public
                  about the sale of general Nursing Admission forms into the
                  School of Nursing, INTERNSHIP FORMS, and also the sale of two
                  (2) years Post Basic Nursing Programme; for 2024/2025 Nursing
                  Admission.
                  <strong>
                    {' '}
                    The School Admin Dr. Mrs Maryam Afolayan, *0806--7---7- is
                    FAKE
                  </strong>
                  <br />
                  This is to inform the General Public to disregard this
                  misleading Information on these Nursing Profession Admissions.
                  The Contact Name and Phone Number stated above are FRAUDULENT
                  AND FALSE <br />
                  <strong>
                    The Logo used is not the logo in use at the Hospital.
                  </strong>
                  <br />
                  The Hospital has published openings for admission into various
                  School programmes in the{' '}
                  <strong>
                    Guardian and Vanguard Newspapers of Tuesday April 9th, 2024.
                  </strong>{' '}
                  <br />
                  Kindly check the Hospital website for the Schools' advert
                  publication or visit the School Administration Building, Lagos
                  University Teaching Hospital (LUTH) for further guidance{' '}
                  <Link to='www.luth.gov.ng'>www.luth.gov.ng</Link>
                </p>
                <p>
                  Thank you. 
                  <br />OLUFEMI A. OLADIRAN 
                  <br />FOR: HEAD, CORPORATE SERVICES/
                  <br />PRO FOR: CHIEF MEDICAL DIRECTOR
                </p>
              </div>
            </div>
            <div className='share-block'>
              {/* <div className='social-block'>
              <a href='#none'>
                <i className='fab fa-twitter'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-facebook-f'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-instagram'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-dribbble'></i>
              </a>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='Fake News  | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
